import React, { Component } from "react"
import axios from "axios"
import { useSpring, animated } from "react-spring"
import ReCAPTCHA from "react-google-recaptcha"

const ThankYouMessage = () => {
  const props = useSpring({
    config: { duration: 1000 },
    to: async next => {
      next({ opacity: 1 })
    },

    from: { opacity: 0 },
  })
  return (
    <animated.div style={props} className="section-title text-left mb--50">
      <h2 className="title">Thanks for inquiring about our services!</h2>
      <p className="description">
        You should be hearing from us soon!
        <br />
        <a href="tel:+16513038668">651-303-8668</a> or email:
        <a href="mailto:john@coachmercer.com">john@coachmercer.com</a>{" "}
      </p>
    </animated.div>
  )
}

const ErrorMessage = () => {
  const props = useSpring({
    config: { duration: 1000 },
    to: async next => {
      next({ opacity: 1 })
    },

    from: { opacity: 0 },
  })
  return (
    <animated.div style={props} className="section-title text-left mb--50">
      <h2 className="title">
        Oops! Something went wrong with your form entry.
      </h2>
      <p className="description">
        We apologize for any inconvenience this may cause. Please refresh the
        page and try again.
        <br />
      </p>
    </animated.div>
  )
}

class ContactThree extends Component {
  constructor(props) {
    super(props)
    this.recaptchaRef = React.createRef()
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      submissionCheck: false,
      formSubmitted: false,
      captchaError: false,
    }

    this.submitForm = this.submitForm.bind(this)
  }

  checkForm = e => {
    e.preventDefault()

    switch (true) {
      case this.state.name === "":
      case this.state.email === "":
      case this.state.message === "":
        this.setState({ submissionCheck: true })
        break

      default:
        this.setState({ submissionCheck: false }, () => {
          const captcha = this.recaptchaRef.current.execute()

          captcha
            .then(data => {
              return true
            })
            .catch(error => {
              return true
            })

          console.log(this.state.captchaError)
        })
    }
  }

  async submitForm() {
    try {
      const { data } = await axios.post("/api/contact", this.state)
      if (data.success) {
        this.setState({
          name: "",
          email: "",
          message: "",
          subject: "",
          submissionCheck: false,
        })
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  renderForm = () => {
    return (
      <>
        <div className="section-title text-left mb--50">
          <h2 className="title">Let's get started!</h2>
          <p className="description">
            New clients get a free exploratory conversation upon request! Setup
            yours now!
            <br />
            <br />
            Mon - Fri: 10:00 a.m. - 4:00 p.m. CST
            <br />
            <br />
            <a href="tel:+16513038668">651-303-8668</a> or email:
            <a href="mailto:john@coachmercer.com">john@coachmercer.com</a>{" "}
          </p>
        </div>
        <div className="form-wrapper">
          <form>
            <label htmlFor="item01">
              <input
                className={`${
                  this.state.submissionCheck && this.state.name === ""
                    ? "error"
                    : ""
                }`}
                type="text"
                name="name"
                id="item01"
                value={this.state.name}
                onChange={e => {
                  this.setState({ name: e.target.value })
                }}
                placeholder="Your Name *"
              />
            </label>

            <label htmlFor="item02">
              <input
                className={`${
                  this.state.submissionCheck && this.state.email === ""
                    ? "error"
                    : ""
                }`}
                type="text"
                name="email"
                id="item02"
                value={this.state.email}
                onChange={e => {
                  this.setState({ email: e.target.value })
                }}
                placeholder="Your email *"
              />
            </label>

            <label htmlFor="item03">
              <input
                type="text"
                name="subject"
                id="item03"
                value={this.state.subject}
                onChange={e => {
                  this.setState({ subject: e.target.value })
                }}
                placeholder="Write a Subject"
              />
            </label>
            <label htmlFor="item04">
              <textarea
                className={`${
                  this.state.submissionCheck && this.state.message === ""
                    ? "error"
                    : ""
                }`}
                type="text"
                id="item04"
                name="message"
                value={this.state.message}
                onChange={e => {
                  this.setState({ message: e.target.value })
                }}
                placeholder="Your Message"
              />
            </label>
            <button
              onClick={this.checkForm}
              className="rn-button-style--2 btn-solid"
              type="submit"
              value="submit"
              name="submit"
              id="mc-embedded-subscribe"
            >
              Submit
            </button>
            <ReCAPTCHA
              badge="bottomleft"
              ref={this.recaptchaRef}
              type="image"
              size="invisible"
              onErrored={() => {
                this.setState({ formSubmitted: true, captchaError: true })
              }}
              onChange={() => {
                this.setState(
                  { formSubmitted: true, captchaError: false },
                  () => {
                    this.submitForm()
                  }
                )
              }}
              sitekey="6LcBYtQZAAAAAOSqrse-x4O_3zdfwawf10yu9a1P"
            />
          </form>
        </div>
      </>
    )
  }

  renderMessage = () => {
    return this.state.captchaError ? <ErrorMessage /> : <ThankYouMessage />
  }

  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row cm-contact-form">
            <div className="col-lg-6">
              {this.state.formSubmitted
                ? this.renderMessage()
                : this.renderForm()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ContactThree
